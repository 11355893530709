import { Enums,
  getRenderingEngine,
  setVolumesForViewports,
  volumeLoader } from '@cornerstonejs/core';
import { Lesions, VolumeIds } from 'types';
import { setTransferFunctionForVolumeActor,
  setupTooling,
  setupVolumes } from 'utils/cornerstone/setup';
import { deactivateVesselTools,
  setupVesselReconstructionTools } from 'utils/cornerstone/vesselTools';
import { ChangeOrientation, getDefaultWindowingValues, getViewport } from 'utils/utilities';
import { NIFTI_ORIENTATIONS, RENDERING_ENGINE_ID, VIEWPORT_ID } from '../../constants';

export async function resetVesselReconstruction() {
  const currentViewport: any = getViewport();
  if (!currentViewport) {
    return;
  }

  currentViewport.setDisplayArea({
    imageArea: [1, 1],
    imageCanvasPoint: {
      imagePoint: [0.5, 0.5],
      canvasPoint: [0.5, 0.5],
    },
  });
  currentViewport.render();
}

export async function activateVesselReconstruction(vesselData: VolumeIds) {
  const currentViewport = getViewport();
  if (!currentViewport) {
    return;
  }
  const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);

  const toolGroup = setupVesselReconstructionTools();
  const { element } = currentViewport;

  const volumeId = `nifti:${vesselData.vessel}`;
  await volumeLoader.createAndCacheVolume(volumeId);

  const viewportInput = [
    {
      viewportId: VIEWPORT_ID,
      type: Enums.ViewportType.VOLUME_3D,
      element,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
      },
    },
  ];

  renderingEngine?.setViewports(viewportInput);
  const viewport = renderingEngine.getViewport(VIEWPORT_ID) as any;

  await setVolumesForViewports(
    renderingEngine,
    [
      {
        volumeId,
        callback: ({ volumeActor }) => {
          if (volumeActor) {
            const mapper = volumeActor.getMapper();
            mapper.setSampleDistance(2.0);
          }
        },
      },
    ],
    [VIEWPORT_ID],
  );

  toolGroup?.addViewport(VIEWPORT_ID, renderingEngine.id);

  viewport.setProperties({
    preset: 'CT-Coronary-Arteries-3',
  });

  viewport.render();
}
export async function deactivateVesselReconstruction(
  activeLesion: Lesions,
  activeVolumesData: VolumeIds,
) {
  const currentViewport = getViewport();
  if (!currentViewport) {
    return;
  }
  const niftiVolumeId = `nifti:${activeVolumesData.image}`;

  try {
    // First deactivate vessel tools
    deactivateVesselTools();
    const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
    if (!renderingEngine) {
      console.error('Rendering engine not found');
      return;
    }

    const element = currentViewport?.element;
    if (!element) {
      console.error('Viewport element not found');
      return;
    }

    try {
      // Load main volume first and wait for it to complete
      await volumeLoader.createAndCacheVolume(niftiVolumeId);
      const windowingDefaults = getDefaultWindowingValues(niftiVolumeId);

      // Create viewport with proper orientation
      const viewportInput = {
        viewportId: VIEWPORT_ID,
        type: Enums.ViewportType.ORTHOGRAPHIC,
        element,
        defaultOptions: {
          orientation: Enums.OrientationAxis.AXIAL,
        },
      };

      await renderingEngine.setViewports([viewportInput]);

      const setCtTransferFunctionForVolumeActor = ({ volumeActor }: any) => {
        if (volumeActor) {
          setTransferFunctionForVolumeActor(
            volumeActor,
            windowingDefaults.windowCenter,
            windowingDefaults.windowWidth,
          );
        }
      };

      // Setup volumes with proper callback
      await setupVolumes(
        {
          main: niftiVolumeId,
          lesions: activeVolumesData?.lesions.map((path: string) => `nifti:${path}`) || [],
        },
        setCtTransferFunctionForVolumeActor,
      );

      // Get the new viewport and ensure it's properly initialized
      const viewport = renderingEngine.getViewport(VIEWPORT_ID);
      if (!viewport) {
        throw new Error('Viewport not initialized after setup');
      }

      // Setup tooling
      const toolGroup = setupTooling();
      toolGroup.addViewport(VIEWPORT_ID, renderingEngine.id);

      // Reset camera and render
      viewport.resetCamera();
      viewport.render();

      // Change orientation after ensuring everything is loaded
      await ChangeOrientation(NIFTI_ORIENTATIONS.AXIAL, activeLesion);
    } catch (volumeError) {
      const viewport = renderingEngine.getViewport(VIEWPORT_ID);
      if (viewport) viewport.render();
    }
  } catch (error) {
    console.error('Error during vessel reconstruction deactivation:', error);
    const viewport = getViewport();
    if (viewport) viewport.render();
  }
}
