import UIDropdown from 'components/ui/dropdown/Dropdown';
import { useActiveVolumes } from 'context/ActiveVolumesContext';
import React, { useEffect, useState } from 'react';
import { formattedImageData, VolumeIds } from 'types';
import { loadNewVolume } from 'utils/cornerstone/setup';
import { applyDefaultColorMap, getDefaultLesion } from 'utils/heatmaps/heatmaps';
import { getViewport } from 'utils/utilities';

export const handleDropdownItemClick = async (
  imageMetaData: VolumeIds,
  setActiveLesion: React.Dispatch<React.SetStateAction<string>>,
  isVesselReconstructionActive?: boolean,
  handleVesselDeactivation?: () => void,
  setActiveVolumeData?: React.Dispatch<React.SetStateAction<any>>,
) => {
  // If vessel reconstruction is active, deactivate it first
  if (isVesselReconstructionActive && handleVesselDeactivation) {
    await handleVesselDeactivation();
  }

  setActiveVolumeData(imageMetaData);
  await loadNewVolume(imageMetaData);

  const defaultLesion = getDefaultLesion();
  setActiveLesion(defaultLesion);
  if (defaultLesion) {
    applyDefaultColorMap();
  }
  const viewport = getViewport();
  if (viewport) {
    viewport.render();
  }
};

type ImageDropdownProps = {
  formattedImages: formattedImageData[];
};

const ImageDropdown = ({ formattedImages }: ImageDropdownProps) => {
  const { activeVolumesData, setActiveVolumeData } = useActiveVolumes();

  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const primaryVolume = activeVolumesData?.image;
    if (!primaryVolume) {
      // image is the selected image
      const selectedImage = formattedImages.find((img) => img.selectedImage);
      if (selectedImage) {
        setActiveIndex(formattedImages.findIndex((img) => img.image === selectedImage.image));
        const volumeIds = {
          image: selectedImage.image,
          vessel: selectedImage.vessel,
          lesions: selectedImage.lesions,
        };
        setActiveVolumeData(volumeIds);
      }
    }
  }, []);

  return <UIDropdown
      items={formattedImages}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
    />;
};

export default ImageDropdown;
