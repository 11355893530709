import { StackIcon } from 'components/ui/icons';
import React from 'react';
import './ViewerFooter.css';

const ViewerFooter = ({ hasRendered, sliderValue, isVesselReconstructionActive }) => {
  const sliceCounter = hasRendered ? `${sliderValue.currentSlice + 1}/${sliderValue.maxSlice}` : '';

  return (
    <div className='bottom-menu'>
      {!isVesselReconstructionActive && (
        <div className='bottom-nav font-weight-500'>
          <StackIcon />
          {sliceCounter}
        </div>
      )}
      <div className='bottom-nav disclaimer'>Not for diagnostic use</div>
    </div>
  );
};

export default ViewerFooter;
