import { handleDropdownItemClick } from 'components/imagedropdown/ImageDropdown';
import { useLesion } from 'context/Lesions';
import { useActiveVolumes } from 'context/ActiveVolumesContext';
import React from 'react';
import { Images, NiftiUrls, Product, formattedImageData } from 'types';
import { deactivateVesselReconstruction } from 'utils/cornerstone/vesselReconstruction';
import { shouldDisplayLesionMenu } from 'utils/heatmaps/heatmaps';
import { formatIsoToMonthDayHourMinute12h } from 'utils/time';
/**
 * Formats an array of images for use in a dropdown component.
 *
 * @param {Images} images - The images object containing metadata for each product.
 * @param {Product} selectedProduct - The selected product type (e.g., 'ce', 'fda', 'res').
 * @param {NiftiUrls} niftiUrls - The Nifti URLs object with image URLs and lesions.
 * @param {string} selectedImage - The ID of the currently selected image.
 * @returns {formattedImageData[]} An array of formatted image data for dropdown display.
 */

export default function formatImageData(
  images: Images,
  selectedProduct: Product,
  niftiUrls: NiftiUrls,
  selectedImage: string,
  setShowLesionMenu: React.Dispatch<React.SetStateAction<boolean>>,
): formattedImageData[] {
  const { setActiveLesion } = useLesion();
  const {
    isVesselReconstructionActive,
    toggleVesselReconstruction,
    activeVolumesData,
    setActiveVolumeData,
  } = useActiveVolumes();

  const handleVesselDeactivation = async () => {
    if (isVesselReconstructionActive) {
      await deactivateVesselReconstruction(null, activeVolumesData);
      toggleVesselReconstruction();
    }
  };

  return Object.entries(images[selectedProduct]).map(([imageId, image]) => {
    const niftiData = niftiUrls[imageId];
    const lesions = niftiData.lesions?.[selectedProduct];
    const isClickable = !!niftiData?.image;

    const imageMetadata = {
      image: niftiData.image ?? null,
      lesions: lesions ? Object.values(lesions).map(String) : [],
      vessel: niftiData.vessel ?? null,
    };

    return {
      image: imageMetadata.image,
      lesions: imageMetadata.lesions,
      vessel: imageMetadata.vessel,
      selectedImage: parseInt(selectedImage, 10) === parseInt(imageId, 10),
      label: `${image.type} ${formatIsoToMonthDayHourMinute12h(image.datetime)}`,
      value: niftiData.image,
      onClick: isClickable
        ? () => {
          setShowLesionMenu(shouldDisplayLesionMenu(selectedProduct, image.type));
          handleDropdownItemClick(
            imageMetadata,
            setActiveLesion,
            isVesselReconstructionActive,
            handleVesselDeactivation,
            setActiveVolumeData,
          );
        }
        : null,
      disabled: !isClickable,
    };
  });
}
