import MipsMenu from 'components/menuInputs/MipsMenu';
import InfoModal from 'components/Modals/infomodal/Infomodal';
import { AxialOrientation,
  CoronalOrientation,
  ExpandIcon,
  InfoIcon,
  MinimizeIcon,
  ResetIcon,
  SagittalOrientation,
  VesselReconstructionIcon,
  WindowingIcon } from 'components/ui/icons';
import UIIconMenu from 'components/ui/menus/icon/IconMenu';
import UiModal from 'components/ui/modal/Modal';
import { useLesion } from 'context/Lesions';
import { useMipsContext } from 'context/MipsContext';
import { useActiveVolumes } from 'context/ActiveVolumesContext';
import { useWindowing } from 'context/Windowing';
import useView from 'hooks/useView';
import React from 'react';
import { activateVesselReconstruction,
  deactivateVesselReconstruction,
  resetVesselReconstruction } from 'utils/cornerstone/vesselReconstruction';
import { getViewport, resetFilters } from 'utils/utilities';
import { VIEW_STATE } from '../../constants';
import OrientationMenu from './OrientationMenu';
import WindowingMenu from './WindowingMenu';

type defaultMenuProps = {
  setTopBar: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

const DefaultCanvasMenu = ({ setTopBar }: defaultMenuProps) => {
  const [activeView, updateView] = useView();
  const isAdvancedMenuOpen = activeView === VIEW_STATE.ADVANCED;
  const mipIcon = <div style={{ fontSize: '12px' }}>MIP</div>;
  const { setMipsValue } = useMipsContext();
  const { activeLesion } = useLesion();

  const { setWindowingValues } = useWindowing();
  const viewPort = getViewport();
  const orientation = viewPort?.defaultOptions?.orientation || 'axial';
  const iconMap = {
    axial: AxialOrientation,
    sagittal: SagittalOrientation,
    coronal: CoronalOrientation,
  };
  const {
    isVesselReconstructionActive,
    toggleVesselReconstruction,
    activeVolumesData,
    hasVesselData,
  } = useActiveVolumes();

  function handleVesselReconstructionToggle() {
    if (isVesselReconstructionActive) {
      deactivateVesselReconstruction(activeLesion, activeVolumesData);
    } else {
      activateVesselReconstruction(activeVolumesData);
    }
    toggleVesselReconstruction();
  }
  /* in the button map below we are intentionally using the isDisabled prop to disable
    a range of different tools that are not available when vessel reconstruction is active */
  const buttons = [
    {
      icon: <UiModal icon={<InfoIcon />} content={<InfoModal />} />,
      isDisabled: isVesselReconstructionActive,
    },
    {
      icon: WindowingIcon,
      onClick: () => setTopBar(<WindowingMenu setTopBar={setTopBar} />),
      isDisabled: isVesselReconstructionActive,
    },
    {
      icon: iconMap[orientation],
      onClick: () => setTopBar(<OrientationMenu setTopBar={setTopBar} />),
      isDisabled: isVesselReconstructionActive,
    },
    {
      icon: mipIcon,
      onClick: () => setTopBar(<MipsMenu setTopBar={setTopBar} />),
      isDisabled: isVesselReconstructionActive,
    },
    {
      icon: <VesselReconstructionIcon />,
      onClick: handleVesselReconstructionToggle,
      isActive: isVesselReconstructionActive,
      hidden: !hasVesselData,
    },
    {
      icon: ResetIcon,
      onClick: async () => {
        if (!isVesselReconstructionActive) {
          return resetFilters(setWindowingValues, setMipsValue);
        }
        return resetVesselReconstruction();
      },
    },
  ];

  return (
    <UIIconMenu
      buttons={buttons}
      rightIcon={
        !isAdvancedMenuOpen
          ? {
            icon: ExpandIcon,
            onClick: () => updateView(VIEW_STATE.ADVANCED),
          }
          : {
            icon: MinimizeIcon,
            onClick: () => updateView(VIEW_STATE.BASE),
          }
      }
    />
  );
};

export default DefaultCanvasMenu;
