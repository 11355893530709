import DefaultMenuCanvas from 'components/menuInputs/DefaultMenuCanvas';
import { useMipsContext } from 'context/MipsContext';
import { useWindowing } from 'context/Windowing';
import React, { useEffect, useState } from 'react';
import './ViewerMenu.css';

type ViewerMenuProps = {
  isVesselReconstructionActive: boolean;
};
export default function ViewerMenu({ isVesselReconstructionActive }: ViewerMenuProps) {
  const [topBar, setTopBar] = useState<React.ReactNode>(null);
  const { mipsValue } = useMipsContext();
  const { windowingValues } = useWindowing();

  useEffect(() => {
    setTopBar(<DefaultMenuCanvas setTopBar={setTopBar} />);
  }, []);

  return (
    <>
      {topBar}
      {!isVesselReconstructionActive && (
        <>
          <div className='canvas-stats'>
            WW/WL: {windowingValues.windowWidth.toFixed(0)}
            /
            {windowingValues.windowCenter.toFixed(0)}
          </div>
          <div className='canvas-stats'>MIPs: {mipsValue.toFixed(2)} mm</div>
        </>
      )}
    </>
  );
}
