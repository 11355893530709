import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { VolumeIds } from 'types';

interface ActiveVolumeContextType {
  isVesselReconstructionActive: boolean;
  toggleVesselReconstruction: () => void;
  activeVolumesData: VolumeIds | null;
  setActiveVolumeData: React.Dispatch<React.SetStateAction<VolumeIds>>;
  hasVesselData: boolean;
}

const ActiveVolumesContext = createContext<ActiveVolumeContextType | undefined>(
  undefined,
);

export const ActiveVolumesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isVesselReconstructionActive, setIsVesselReconstructionActive] = useState(false);
  const [activeVolumesData, setActiveVolumeData] = useState<VolumeIds>(null);

  const hasVesselData = useMemo(() => Boolean(activeVolumesData?.vessel && typeof activeVolumesData.vessel === 'string'), [activeVolumesData]);

  useEffect(() => {
    if (!hasVesselData && isVesselReconstructionActive) {
      setIsVesselReconstructionActive(false);
    }
  }, [hasVesselData]);

  const toggleVesselReconstruction = () => {
    if (!hasVesselData) return;
    setIsVesselReconstructionActive((prev) => !prev);
  };

  return (
    <ActiveVolumesContext.Provider
      value={{
        isVesselReconstructionActive,
        toggleVesselReconstruction,
        activeVolumesData,
        setActiveVolumeData,
        hasVesselData,
      }}
    >
      {children}
    </ActiveVolumesContext.Provider>
  );
};

export const useActiveVolumes = () => {
  const context = useContext(ActiveVolumesContext);
  if (!context) {
    throw new Error('useActiveVolumes must be used within a ActiveVolumesProvider');
  }
  return context;
};
