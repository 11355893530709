import * as cornerstoneTools from '@cornerstonejs/tools';
import { RENDERING_ENGINE_ID,
  VIEWPORT_ID, VESSEL_RECONSTRUCTION_TOOL_GROUP_ID } from '../../constants';

const {
  ToolGroupManager,
  TrackballRotateTool,
  VolumeRotateMouseWheelTool,
  ZoomTool,
  Enums: csToolsEnums,
} = cornerstoneTools;

const vesselReconstructionTools = [
  {
    toolName: TrackballRotateTool.toolName,
    toolClass: TrackballRotateTool,
    bindings: [
      {
        mouseButton: csToolsEnums.MouseBindings.Secondary,
      },
      {
        numTouchPoints: 1,
      },
    ],
  },
  {
    toolName: VolumeRotateMouseWheelTool.toolName,
    toolClass: VolumeRotateMouseWheelTool,
    options: {
      configuration: {
        rotateIncrementDegrees: 2,
      },
    },
  },
  {
    toolName: ZoomTool.toolName,
    toolClass: ZoomTool,
    bindings: [
      {
        mouseButton: csToolsEnums.MouseBindings.Primary,
      },
      {
        numTouchPoints: 2,
      },
    ],
  },
];

function isToolAlreadyAdded(toolName: string): boolean {
  return cornerstoneTools.state.tools[toolName] !== undefined;
}
export function setupVesselReconstructionTools() {
  // First, clean up ALL existing tool groups for this viewport
  const allToolGroups = ToolGroupManager.getAllToolGroups();

  // Clean up existing tool groups
  allToolGroups.forEach((group) => {
    const hasViewport = group
      .getViewportsInfo()
      .some(
        (info) => info.viewportId === VIEWPORT_ID
          && info.renderingEngineId === RENDERING_ENGINE_ID,
      );

    if (hasViewport) {
      // Remove viewport from group
      group.removeViewports(RENDERING_ENGINE_ID, VIEWPORT_ID);

      // Destroy the group if it's our vessel reconstruction group
      if (group.id === VESSEL_RECONSTRUCTION_TOOL_GROUP_ID) {
        ToolGroupManager.destroyToolGroup(VESSEL_RECONSTRUCTION_TOOL_GROUP_ID);
      }
    }
  });

  // Create new tool group
  const toolGroup = ToolGroupManager.createToolGroup(
    VESSEL_RECONSTRUCTION_TOOL_GROUP_ID,
  );

  // Add tools to cornerstone if they haven't been added already
  vesselReconstructionTools.forEach(({ toolName, toolClass, options }) => {
    if (!isToolAlreadyAdded(toolName)) {
      cornerstoneTools.addTool(toolClass);
    }

    // Add tool to group with configuration
    toolGroup?.addTool(toolName, {
      ...options,
      configuration: {
        ...options?.configuration,
        preventRenderOnChange: true,
      },
    });
  });

  // Activate tools with bindings
  vesselReconstructionTools.forEach(({ toolName, bindings }) => {
    if (bindings) {
      toolGroup?.setToolActive(toolName, { bindings });
    } else {
      toolGroup?.setToolActive(toolName);
    }
  });

  // Add viewport to group
  toolGroup?.addViewport(VIEWPORT_ID, RENDERING_ENGINE_ID);

  return toolGroup;
}

export function deactivateVesselTools() {
  const toolGroup = ToolGroupManager.getToolGroup(
    VESSEL_RECONSTRUCTION_TOOL_GROUP_ID,
  );
  if (toolGroup) {
    // Remove viewport from group before destroying
    toolGroup.removeViewports(RENDERING_ENGINE_ID, VIEWPORT_ID);
    // Deactivate all tools in the group
    vesselReconstructionTools.forEach(({ toolName }) => {
      toolGroup.setToolDisabled(toolName);
    });
    ToolGroupManager.destroyToolGroup(VESSEL_RECONSTRUCTION_TOOL_GROUP_ID);
  }
}
