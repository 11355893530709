import { Types } from '@cornerstonejs/core';
import { IVolumeViewport } from '@cornerstonejs/core/dist/types/types';
import { getViewport } from 'utils/utilities';
import { ANATOMICAL_AXES } from '../../constants';

/**
 * Get the orientation of the viewport and return the spacing of the current slice
 * @returns the spacing of the slices on the current axis
 */
export default function getCurrentSliceSpacing(): number | void {
  const viewport = getViewport() as IVolumeViewport;
  if (!viewport || !viewport?.getImageData()) return 0;

  const { orientation } = viewport.defaultOptions;
  // get the general spacing of the volume
  const { spacing } = viewport.getImageData();
  // get the index of the current axis
  const currentAxisIndex = ANATOMICAL_AXES[orientation.toUpperCase()];
  // return the spacing of the current axis
  return spacing[currentAxisIndex];
}

export function setMipsDefaultValues(setMipsValue) {
  const viewport = getViewport() as Types.IVolumeViewport;
  if (!viewport || !viewport?.getImageData()) return;

  const spacingOnAxis = getCurrentSliceSpacing();
  if (!spacingOnAxis) return;
  const isNcct = viewport.getDefaultActor().uid.toLowerCase().includes('ncct');
  if (isNcct) {
    setMipsValue(0, spacingOnAxis);
    return;
  }

  // we can assume that this is CTA which should have a mips value closest to 10mm
  const targetMipThicknessMm = 10;
  // figure out how many slices we need to make the mips value closest to 10mm
  const thicknessScaleFactor = Math.round(targetMipThicknessMm / spacingOnAxis);
  // Calculate actual thickness that's closest to 10mm using whole slices
  const AdjustedMipThickness = spacingOnAxis * thicknessScaleFactor;

  setMipsValue(AdjustedMipThickness, AdjustedMipThickness + spacingOnAxis);
}
