import { eventTarget } from '@cornerstonejs/core';
import { Enums as NiftiEnums } from '@cornerstonejs/nifti-volume-loader';
import { useEffect, useState, useCallback, useRef } from 'react';

export default function useLoadingProgress() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const startTime = useRef(0);

  function stopLoading() {
    setIsLoading(false);
    setLoadingProgress(0);
    startTime.current = 0;
  }

  const handleVolumeProgress = useCallback((event: any) => {
    // TODO make sure this works with prod data
    const isVessel = event.detail.data.volumeId.includes('vessel');
    if (isVessel) {
      return;
    }
    setIsLoading(true);
    if (startTime.current === 0) {
      startTime.current = Date.now();
    }
    const { loaded, total } = event.detail.data;
    const progress = Math.round((loaded / total) * 100);
    setLoadingProgress(progress);
  }, []);

  const handleIsLoaded = useCallback(() => {
    const elapsedTime = Date.now() - startTime.current;
    const remainingTime = Math.max(1000 - elapsedTime, 0);
    if (remainingTime === 0) {
      stopLoading();
    } else {
      setTimeout(stopLoading, remainingTime);
    }
  }, []);
  useEffect(() => {
    eventTarget.addEventListener(NiftiEnums.Events.NIFTI_VOLUME_PROGRESS, handleVolumeProgress);
    eventTarget.addEventListener(NiftiEnums.Events.NIFTI_VOLUME_LOADED, handleIsLoaded);
    return () => {
      eventTarget.removeEventListener(
        NiftiEnums.Events.NIFTI_VOLUME_PROGRESS,
        handleVolumeProgress,
      );
      eventTarget.removeEventListener(NiftiEnums.Events.NIFTI_VOLUME_LOADED, handleIsLoaded);
    };
  }, []);

  return { isLoading, loadingProgress };
}
