import './styles/style_normalize.css';
import './styles/styles.css';
import Support from 'pages/support/Support';
import { ThemeProvider } from '@mui/material';
import handleConfigureMode from 'utils/teams';
import MainView from 'pages/MainView';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import theme from 'styles/theme';
import { MipsProvider } from 'context/MipsContext';
import { WindowingProvider } from 'context/Windowing';
import { LesionProvider } from 'context/Lesions';
import Skeleton from 'components/ui/skeleton/Skeleton';
import ConfigPage from 'pages/Config';
import { initialize } from '@microsoft/teams-js';
import { ActiveVolumesProvider } from 'context/ActiveVolumesContext';

const data = require('./mockups/example_diag_response.json');

export type AppState = 'LOADING' | 'ERROR' | 'SUCCESS';

const App = () => {
  if (window.location.href.includes('?help')) {
    return <Support />;
  }
  initialize();
  const url = window.location.href;
  if (url.includes('configure')) {
    handleConfigureMode();
    return <ConfigPage />;
  }

  const [appState, setAppState] = useState<AppState>('LOADING');

  useEffect(() => {
    if (data) {
      setAppState('SUCCESS');
    }
  }, [data]);
  return (
    <ThemeProvider theme={theme}>
      <ActiveVolumesProvider>
        <WindowingProvider>
          <LesionProvider>
            <MipsProvider>
              {appState === 'LOADING' && <Skeleton />}
              {appState === 'SUCCESS' && <MainView currentCase={data} />}
            </MipsProvider>
          </LesionProvider>
        </WindowingProvider>
      </ActiveVolumesProvider>
    </ThemeProvider>
  );
};

const rootElement = document.getElementById('root');

if (rootElement) {
  createRoot(rootElement).render(<App />);
}
